ejs-grid {
    .e-emptyrow {
        display: none;
    }
}

/* ########################################## */
/* syncfusion grid style */
.e-grid * {
    font-size: $font-size-base !important;
}

/* grid 백그라운드 컬러 */
.e-grid .e-content {
    background-color: #dee2e6;
}

/* grid 폰트 조정에 따른 paging 영역 높이 조절 */
.e-pager .e-pagercontainer .e-firstpage,
.e-pager .e-pagercontainer .e-prevpage,
.e-pager .e-pagercontainer .e-firstpagedisabled,
.e-pager .e-pagercontainer .e-prevpagedisabled,
.e-pager .e-pagercontainer .e-nextpage,
.e-pager .e-pagercontainer .e-lastpage,
.e-pager .e-pagercontainer .e-nextpagedisabled,
.e-pager .e-pagercontainer .e-lastpagedisabled {
    padding: 9px 12px 12px 11.5px !important;
}



/* ########################################## */
/* syncfusion richtexteditor style */
.e-richtexteditor .e-rte-content .e-content, .e-richtexteditor .e-source-content .e-content {
    min-height: 400px;
}
.e-richtexteditor .e-rte-srctextarea {
    min-height: 400px;
}