/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo.grid.grouppanel
.wj-grouppanel {
    display: block;
    background: $wj-cell-grp-bkg;
    padding: 15px;
    min-height: 1em; // workaround for IE11 bug (reported by OutSystems)

    .wj-groupmarker {
        margin-right: 10px;
        padding: 6px 16px;
        border: $wj-cell-bdr;
        cursor: pointer;

        &:hover {
            background: $wj-hdr-hvr;
        }

        .wj-glyph-drag {
            margin: 0 10px 0 0;
        }

        .wj-filter {
            margin: 0 0 0 10px;
            cursor: pointer;
            opacity: .5;

            &:hover, &.wj-filter-on {
                opacity: 1;
            }
        }

        .wj-remove {
            cursor: pointer;
            opacity: .5;
            padding: 12px 0 12px 12px;

            &:hover {
                opacity: 1;
            }
        }

        span {
            opacity: .5;

            &:hover {
                opacity: 1;
            }
        }
    }

    &.wj-rtl {
        .wj-groupmarker {
            .wj-glyph-drag {
                margin: 0 0 0 10px;
            }

            .wj-filter {
                margin: 0 10px 0 0;
            }

            .wj-remove {
                padding: 12px 12px 12px 0;
            }
        }
    }
}

