/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo.grid
.wj-flexgrid .wj-cell {

    // maker content
    .wj-cell-maker {
        width: 100%;
        height: 100%;
    }

    // maker buttons
    &:not([wj-state-measuring]) // WJM19444: no absolute positioning while measuring
    button.wj-cell-maker { 
        position: absolute;
        left: 0;
        top: 0;
        color: $wj-txt;
    }

    // maker links
    &.wj-state-selected, 
    &.wj-state-multi-selected {
        a.wj-cell-maker {
            color: inherit;
        }
    }
    
    // maker images
    img.wj-cell-maker {
        width: unset;
    }

    // spark lines, bars, etc
    .wj-cell-maker svg {
        width: 100%;
        height: 100%;
        overflow: visible;
        stroke: #376092;
        fill: #376092;

        rect, circle {
            stroke-width: 0;
        }

        line {
            stroke-width: 2px;

            &.x-axis {
                stroke-width: 0.5px;
                stroke-opacity: 0.5;
            }
        }

        .wj-marker  {
            fill: #d00000;
            opacity: 1;
        }
    }

    // rating
    &.wj-cell-maker.wj-radio-map {
        label {
            position: relative;
            width: 1em;
            transform: scale(0.1);
            color: orange;
            margin: 0 1em 0 0; // space to click the cell between labels

            input, input:not(:checked) { // hide input elements
                opacity: 0;
            }

            &:after { // show rating symbol (star)
                position: absolute;
                transform: scale(20); // twice the original size: big stars
                cursor: pointer;
                content: '\2605'; // \272a \2606 \272e \2729 https://www.htmlsymbols.xyz/star-symbols
                height: 100%; // WJM-22009
            }

            &:hover { // hovering over label (symbol)
                opacity: 1;
                color: orangered;
                transition: color 600ms;
            }

            &.wj-chk-hidden {
                opacity: 0; // hide this label and
                margin: 0 0 0 -1em; // position before the first one
            }
            
            &.wj-chk-off:not(:hover):not(.wj-chk-hidden) { // dim options after the checked one
                opacity: .15;
            }
        }
        
        &[aria-readonly=true] label { // no hover effects on read-only grids
            pointer-events: none;
        }
    }
}
