/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// glyphs
// https://css-tricks.com/the-shapes-of-css/
.wj-glyph {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    opacity: .75;
    white-space: normal; // TFS 418680
    cursor: pointer;
}

.wj-glyph-asterisk {
    @extend .wj-glyph;
    &:after {
        content: '\2731\fe0e'
    }
}

.wj-glyph-calendar {
    @extend .wj-glyph;
    width: 1em;
    height: 1em;
    border: .1em solid;
    border-top: .3em solid;
    top: .15em; // TFS 440092
    &:after { // no text: TFS 379685
        position: absolute;
        left: .3em;
        top: .1em;
        width: .2em;
        height: .4em;
        border-right: .1em solid;
        border-top: .07em solid;
        opacity: 0.7;
        content: ' ';
    }  
}

.wj-glyph-check {
    @extend .wj-glyph;
    &:after {
        font-weight: bold;
        content: '\2714\fe0e';
    }
}

.wj-glyph-circle {
    @extend .wj-glyph;
    border: .3em solid;
    border-radius: 50%;
    top: -.1em; // TFS 440092
}

.wj-glyph-clipboard {
    @extend .wj-glyph;
    &:after {
        content: '\1f4cb\fe0e'
    }
}

.wj-glyph-clock {
    @extend .wj-glyph;
    width: 1em;
    height: 1em;
    border: .1em solid;
    border-radius: 50%;
    top: .15em; // TFS 440092
    &:after {
        position: absolute;
        border-left: .1em solid;
        border-bottom: .1em solid;
        width: .25em;
        height: .3em;
        top: .2em;
        left: .35em;
        content: ' ';
    }
}

.wj-glyph-diamond {
    @extend .wj-glyph;
    &:after {
        content: '\25c6\fe0e'
    }
}

.wj-glyph-dimension {
    @extend .wj-glyph;
    &:after {
        content: '\27c0\fe0e'
    }
}

.wj-glyph-down {
    @extend .wj-glyph;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
}

.wj-glyph-down-left {
    @extend .wj-glyph;
    border-top: .65em solid transparent;
    border-left: .65em solid;
}

.wj-glyph-down-right {
    @extend .wj-glyph;
    top: -2px;
    border-bottom: .65em solid;
    border-left: .65em solid transparent;
}

.wj-glyph-drag {
    @extend .wj-glyph;
    width: 1em;
    height: 1em;
    &:after {
        position: absolute;
        top: 0.15em;
        left: 0.1em;
        width: 1em;
        height: 1em;
        background: radial-gradient(#000 1px, transparent 2px);
        background-size: 0.33em 0.33em;
        content: ' ';
    }
}

.wj-glyph-file {
    @extend .wj-glyph;
    border-left: .7em solid;
    height: .85em;
    &:after {
        position: absolute;
        transform: translateX(-100%);
        border-top: .3em solid transparent;
        border-left: .3em solid white;
        content: ' ';
    }
}

.wj-glyph-left {
    @extend .wj-glyph;
    top: 1px;
    border-top: .4em solid transparent;
    border-right: .5em solid;
    border-bottom: .4em solid transparent;
}

.wj-glyph-measure {
    @extend .wj-glyph;
    &:after {
        content: '\1f4ca\fe0e'
    }
}

.wj-glyph-pin {
    @extend .wj-glyph;
    top: -.1em;
    left: .1em;
    width: .4em;
    border-top: .6em solid;
    &:before {
        position: absolute;
        border-left: .7em solid;
        height: .1em;
        left: -.15em;
        top: -.1em;
        content: ' ';
    }
    &:after {
        position: absolute;
        border-left: .15em solid;
        height: .5em;
        left: .15em;
        top: -.1em;
        content: ' ';
    }
}

.wj-glyph-plus, .wj-glyph-minus {
    @extend .wj-glyph;
    border-top: .25em solid;
    width: .9em;
    top: -.3em;
}
.wj-glyph-plus:after {
    position: absolute;
    box-sizing: border-box;
    border-left: .25em solid;
    width: .25em;
    height: .95em;
    left: .33em;
    top: -.6em;
    content: ' ';
}

.wj-glyph-pencil {
    @extend .wj-glyph;
    &:after {
        content: '\270e\fe0e'
    }
}

.wj-glyph-right {
    @extend .wj-glyph;
    top: 1px;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid;
}

.wj-glyph-square {
    @extend .wj-glyph;
    border: .25em solid;
}

.wj-glyph-step-backward {
    @extend .wj-glyph;
    border-top: .4em solid transparent;
    border-right: .5em solid;
    border-bottom: .4em solid transparent;
    top: .12em; // TFS 440092
    &:after {
        position: absolute;
        border-left: .2em solid;
        height: .75em;
        transform: translate(-100%,-50%);
        content: ' ';
    }
}
[dir=rtl] .wj-glyph-step-backward:after {
    transform: translate(0, -50%);
}

.wj-glyph-step-forward {
    @extend .wj-glyph;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .5em solid;
    &:after {
        position: absolute;
        border-left: .2em solid;
        height: .75em;
        transform: translateY(-50%);
        content: ' ';
    }
}
[dir=rtl] .wj-glyph-step-forward:after {
    transform: translate(100%, -50%);
}

.wj-glyph-up {
    @extend .wj-glyph;
    border-right: .4em solid transparent;
    border-bottom: .5em solid;
    border-left: .4em solid transparent;
}

.wj-glyph-up-left {
    @extend .wj-glyph;
    border-bottom: .65em solid transparent;
    border-left: .65em solid;
}

.wj-glyph-up-right {
    @extend .wj-glyph;
    border-top: .65em solid;
    border-left: .65em solid transparent;
}

.wj-glyph-filter {
    @extend .wj-glyph;
    top: -.1em;
    width: .5em;
    border-top: .5em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
    &:after {
        position: absolute;
        border-left: .25em solid; // TFS 457654
        left: -.125em;
        top: -.2em;
        height: .4em;
        content: ' ';
    }
}

