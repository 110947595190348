$np-flex-form-border-top: 1px solid #444 !default;
$np-flex-form-td-border: 1px solid #ddd !default;

$np-flex-form-header-bg: #f7f7f7 !default;
$np-flex-form-header-color: #777 !default;
$np-flex-form-content-color: #444 !default;

$table-form-input-padding-x: 0.75rem !default;
$table-form-input-padding-y: 0.375rem !default;

.np-form-table {
    label {
        display: inline-block;
        margin-bottom: 0;
    }

    // np-flex-form의 최상단 border
    border-top: $np-flex-form-border-top;

    // row
    .row {
        margin-right: 0;
        margin-left: 0;

        >.col,
        >[class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }

    }

    // column
    .td,
    [class*="td-"] {
        display: flex;

        // cell
        >.cell,
        >[class*="cell-"] {
            flex-basis: 0;
            flex-grow: 1;
            // max-width: 100%;
            padding: 4px;
            border-bottom: $np-flex-form-td-border;
            background-color: white;
        }

        .cell-head {
            padding: calc(#{$table-form-input-padding-y} + 4px) calc(#{$table-form-input-padding-x} + 4px);
            line-height: 1.5;

            background: $np-flex-form-header-bg;
            border-right: $np-flex-form-td-border;

            text-align: center;
            min-width: 8rem;
            max-width: 8rem;
        }

        .cell-text {
            padding: calc(#{$table-form-input-padding-y} + 4px) calc(#{$table-form-input-padding-x} + 4px);
            line-height: 1.5;
            word-break: break-all;

            &.ellipsis {
                overflow: hidden; // 해당 element 에서 넘어가는 view는 숨긴다.
                text-overflow: ellipsis; // text 가 넘어가는 경우 ellipsis 즉, ... 을 나타내준다.
                white-space: nowrap;

                &:hover {
                    text-overflow: clip;
                    white-space: normal;
                    word-break: break-all;
                }
            }
        }
    }
}