/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// flex-chart.finance
.wj-flexchart {
    .fibonacci-arcs path.wj-state-selected {
        stroke-dasharray: 5 10;
        stroke-linecap: butt;
    }
}