:root {

    // default setting
    --np-sba-text-color: #111;
    --np-sba-nav-active-color: #fff;
    --np-sba-nav-active-bg: #145DA0;
    --np-sba-nav-hover-color: #fff;
    --np-sba-nav-hover-bg: #2E8BC0;
    --np-sba-top: var(--app-top-padding, 0px);

    // sidebar-wrapper
    --np-sba-sidebar-wrapper-bg: rgba(248, 249, 250, 1) !important;
    --np-sba-sidebar-wrapper-border: 1px solid var(--bs-border-color);
    --np-sba-sidebar-wrapper-scroll-width: 6px;
    --np-sba-mobile-backdrop-bg: rgba(0, 0, 0, 0.5);

    // NP-SBA-GROUP
    --np-sba-nav-group-label-color: var(--bs-gray-600);

    // np-sba-nav-menu
    --np-sba-nav-menu-color: var(--np-sba-text-color, var(--bs-body-color));

    --np-sba-nav-menu-active-color: var(--np-sba-nav-active-color, #fff);
    --np-sba-nav-menu-active-bg: var(--np-sba-nav-active-bg, var(--bs-primary));

    --np-sba-nav-menu-hover-color: var(--np-sba-nav-active-bg, var(--bs-primary));
    --np-sba-nav-menu-hover-bg: var(--np-sba-nav-hover-bg, var(--bs-primary));

    // np-sba-nav-submenu
    --np-sba-nav-submenu-color: var(--np-sba-text-color, var(--bs-body-color));

    --np-sba-nav-submenu-active-color: var(--np-sba-nav-active-color, #fff);
    --np-sba-nav-submenu-active-bg: var(--np-sba-nav-active-bg, var(--bs-primary));

    --np-sba-nav-submenu-hover-color: var(--np-sba-nav-hover-color, var(--bs-body-color));
    // --np-sba-nav-submenu-hover-bg: #000C6612;
    --np-sba-nav-submenu-hover-bg: var(--np-sba-nav-hover-bg, rgba(0, 0, 0, 0.15));
}

.np-sba-layout {

    display: contents;
    // height: 100%;

    .np-sba-mobile-backdrop {
        display: none;
        position: fixed;
        top: var(--np-sba-top);
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1028;
        background-color: var(--np-sba-mobile-backdrop-bg, rgba(0, 0, 0, 0.5));
    }

    .np-sba-sidebar-wrapper {
        position: fixed;
        top: var(--np-sba-top);
        left: 0;
        bottom: 0;
        z-index: 1029;
        background-color: var(--np-sba-sidebar-wrapper-bg);
        border: var(--np-sba-sidebar-wrapper-border);
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            /* 스크롤바의 너비 */
            width: 0px;
        }

        &::-webkit-scrollbar-thumb {
            visibility: hidden;
            /* 스크롤바의 길이 */
            height: 30%;
            /* 스크롤바의 색상 */
            background: #9b9b9b;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            /*스크롤바 뒷 배경 색상*/
            background: rgba(135, 135, 135, 0.1);

        }

        &:hover {
            &::-webkit-scrollbar-thumb {
                visibility: visible !important;
            }

            &::-webkit-scrollbar {
                /* 스크롤바의 너비 */
                width: var(--np-sba-sidebar-wrapper-scroll-width);
            }
        }
    }

    // sba 안에서 한번 더 nav를 담고있는 wrapper
    .np-sba-nav-wrapper {
        // margin-left: var(--np-sba-sidebar-wrapper-scroll-width);
        // width: calc(var(--np-sba-full-width) - calc(var(--np-sba-sidebar-wrapper-scroll-width) * 2)) !important;
        // padding: 1rem;
    }

    // 컨텐츠 영역
    .np-sba-content-wrapper {
        // display: flex !important;
        // flex-direction: column !important;
        height: 100%;
        min-height: 1%;
        // overflow: auto;

        // breadcrumb
        .np-sba-breadcrumb {
            position: fixed;
            // position: sticky;
            top: var(--np-sba-top);
            width: 100%;
            background-color: #fff;
            z-index: 10;
            padding: 0.5rem 1rem !important;
            border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;

            .breadcrumb {
                margin-bottom: 0 !important;
            }

            li.breadcrumb-item {
                a {
                    color: var(--bs-body-color);
                    text-decoration: none;
                }
            }
        }

        // 컨텐츠
        .np-sba-content {
            height: 100%;
            min-height: 1%;
            padding-top: calc(35px + 0.5rem);
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 0.5rem;
            // padding: 1rem !important;
            // overflow: auto;
        }

    }

    //
    // GROUP
    //

    .np-sba-nav-group-item {
        margin-bottom: 1rem;
    }

    .np-sba-nav-group-divider {
        display: none;
    }

    .np-sba-nav-group-label {
        font-size: .7rem;
        font-weight: 700;
        color: var(--np-sba-nav-group-label-color);
        padding-bottom: 0.25rem;
        padding-left: 1rem;
    }

    //
    // MENU
    //

    .np-sba-nav-menu-item {
        margin: 0.1rem 0.1rem;
        cursor: pointer;
        white-space: nowrap;

        .nav-link {
            color: var(--np-sba-nav-menu-color);
        }

        .nav-link:hover,
        &.show>.nav-link {
            color: var(--np-sba-nav-menu-active-color);
            background-color: var(--np-sba-nav-menu-hover-bg);
        }

        .nav-link.active {
            color: var(--np-sba-nav-menu-active-color);
            background-color: var(--np-sba-nav-menu-active-bg);
        }

        .np-sba-nav-menu-icon {
            display: inline-block;
            width: 1.3rem;
            // padding-left: 0rem !important;
            // padding-right: 0.5rem !important;
            text-align: center;
            margin-right: 0.3rem !important;
            // background-color: red;
        }

        // submenu box
        .dropdown-menu {
            position: relative !important;
            background-color: transparent;
            margin-left: 1.5rem;
            padding: 0.25rem 1rem;
            border: none;
            border-radius: 0;
            border-left: 1px dashed rgba(55, 60, 67, .45);

            // submenu item
            .dropdown-item {
                // position: relative !important;
                // text-decoration: none !important;
                // width: auto;
                cursor: pointer;
                margin-top: 0.1rem;
                margin-bottom: 0.1rem;
                padding-top: 0.4rem;
                padding-bottom: 0.4rem;
                color: var(--np-sba-nav-submenu-color);
                border-radius: 0;

                &:hover,
                &:focus {
                    color: var(--np-sba-nav-submenu-hover-color) !important;
                    background-color: var(--np-sba-nav-submenu-hover-bg) !important;
                    border-radius: 0.25rem;
                }

                &.active {
                    color: var(--np-sba-nav-submenu-color) !important;
                    background-color: transparent !important;
                    font-weight: 700;
                    border-radius: 0;
                    border-bottom: 1px dashed var(--np-sba-nav-submenu-active-bg);

                    &:hover {

                        // 선택된것 무시
                        // color: var(--np-sba-nav-submenu-color) !important;
                        // background-color: transparent !important;
                        // border-bottom: 2px dashed var(--np-sba-nav-submenu-active-bg);
                        // font-weight: 700;


                        // 박스표시
                        border-radius: 0 !important;
                        color: var(--np-sba-nav-submenu-active-color) !important;
                        background-color: var(--np-sba-nav-submenu-active-bg) !important;
                        border-radius: 0.25rem !important;
                    }
                }

                .href-link {
                    color: blue !important;
                }

                .np-sba-nav-submenu-icon {
                    margin-right: 0.5rem;
                }
            }
        }
    }



    // ******************************************************************************
    // 크기 및 상황별 동작 정의
    // ******************************************************************************

    // 'sba-full' | 'sba-collapsed' | 'sba-collapsed-open' | 'sba-mobile-open' | 'sba-none';

    &.sba-full,
    &.sba-collapsed-open,
    &.sba-mobile-open {

        // 큰 화면인 경우 선택된 toggle의 형제 dropdown을 항상 보여준다.
        .np-sba-nav-menu-item {
            .nav-link.active+.dropdown-menu {
                display: block !important;
            }
        }
    }



    // [모바일 오픈]
    &.sba-mobile-open {
        .np-sba-mobile-backdrop {
            display: block;
        }

        .np-sba-sidebar-wrapper {
            display: block;
        }
    }

    &.sba-collapsed {

        // .np-sba-nav-wrapper {
        //     padding: 0;
        // }

        .np-sba-nav-group-item {
            margin: 0;
        }

        .np-sba-nav-group-divider {
            display: block;
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            width: 80%;
        }

        .np-sba-nav-group-label {
            display: none;
        }

        .np-sba-nav-menu-item {
            margin: 0;
        }

        // 접혔을때 아이콘
        .np-sba-nav-menu-icon {
            width: 2rem;
            margin: 0;
            font-size: 1.3rem;
        }

        .np-sba-nav-menu-label {
            display: none;
        }

        .np-sba-nav-menu-item .nav-link {
            padding: 0.9rem 0rem;
            border-radius: 0;
            text-align: center;
        }

        .dropdown-toggle::after {
            content: none;
        }


    }



}