.list-action-btn {
    min-width: 4rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.btn-4rem {
    min-width: 4rem;
}

.wijmo-bs5-form {

    $np-fcv-invalid-color: #dc3545;

    .wj-clear-x {
        position: absolute;
        margin-left: -3rem;
        margin-top: 0.25rem;
    }

    .np-fcv-invalid {
        .wj-control {
            border-color: $np-fcv-invalid-color;
        }

        // wj-control wj-content wj-dropdown wj-state-collapsed wj-combobox wj-autocomplete ng-touched ng-pristine ng-invalid wj-state-empty
        // wj-bs5-form 
    }

    wj-flex-grid-search {
        width: 100%;

        .wj-form-control {
            padding: 0.375rem 0.75rem !important
        }
    }

    wj-auto-complete {
        width: 100%;

        .wj-form-control {
            padding: 0.375rem 0.75rem !important
        }
    }

    wj-combo-box {
        width: 100%;

        .wj-form-control {
            padding: 0.375rem 0.75rem !important
        }
    }

    .wj-clear-x {
        // background-color: red;
        padding: 0.1rem 0.1rem !important;
        position: absolute;
        margin-left: -3rem;
        margin-top: 0.25rem;
        cursor: pointer
    }
}

/* 읽기 전용 입력 필드의 배경색을 변경 */
// input[readonly] {
//     background-color: #f0f0f0;
//     /* 새로운 배경색 */
// }

/* 읽기 전용 입력 필드의 테두리 색상 변경 */
// input[readonly] {
//     border-color: #ccc;
//     /* 새로운 테두리 색상 */
// }

// .np-modal-wrapper {
//     display: flex;
//     flex-direction: column;
//     overflow: hidden;
//     height: 100%;
// }

.modal-content {
    border: none;
}

.modal-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: rgb(43, 74, 114);
    color: #fff;
    border-radius: 0;
}

.modal-footer {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 0;
    background-color: rgb(240, 240, 240);
}

.modal-content {
    border-radius: 0;
}

.modal-backdrop {
    background-color: #3a4d5e;
}

.cursor-pointer {
    cursor: pointer;
}