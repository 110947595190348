/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo.grid.selector

.wj-flexgrid {
    .wj-cell {
        input.wj-column-selector {
            margin: 4px;
            vertical-align: bottom;
            outline: none;

            &.wj-column-selector-group {
                transform: scale(1.25);
                margin-right: 6px;
            }
        }
    }
}