/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo.grid.search

.wj-content.wj-flexgridsearch { // see _input.css
    vertical-align: middle; // so labeledinput.css works in IE
    display: inline-table; // so height:100% works properly even without <!DOCTYPE html> tag
    border-collapse: separate;
}

.wj-flexgrid {
    .wj-cell span.wj-state-match {
        background: yellow;
        color: $wj-txt; // make this readable when the cell is selected
        font-weight: bold;
    }
}
