@import "./styles/np-bs5-style-1.scss";
@import "bootstrap-icons/font/bootstrap-icons.css";

@import "../../../newpla-modules-ng17/newpla-ng-lib/src/bootstrap5/styles/np-form-table.scss";
// @import "../../../newpla-modules-ng17/newpla-libs/src/np-sticky-table/np-sticky-table.scss";

@import "../../../newpla-modules-ng17/newpla-common/styles/np-fcv-name/np-fcv-name.scss";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////// np-sba-layout ::: START
@import "../../../newpla-modules-ng17/newpla-common/styles/np-sba-layout/np-sba-layout.scss";

/////////////////////////////////////////////////////////////////////////////////////////////// np-sba-layout ::: END
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////// WIJMO ::: START
@import "@grapecity/wijmo.styles/wijmo.scss";
@import "../../../newpla-modules-ng17/newpla-wijmo/styles/np-wijmo-theme-1.scss";

wj-flex-grid {
    height: 100%;
    min-height: 400px;
}

/////////////////////////////////////////////////////////////////////////////////////////////// WIJMO ::: END
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////// SYNCFUSION ::: START
// @import url('//cdn.syncfusion.com/ej2/bootstrap5.css');
@import "@syncfusion/ej2-bootstrap5-theme/styles/bootstrap5.css";
// @import 'node_modules/@syncfusion/ej2-material-theme/styles/material.css';
@import "../../../newpla-modules-ng17/newpla-ej2/styles/ej2-bootstrap5.scss";
// @import "newpla-modules-ng15/newpla-ej2/styles/np-ej2-grid-1.scss";
/////////////////////////////////////////////////////////////////////////////////////////////// SYNCFUSION ::: END
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@import "@fortawesome/fontawesome-free/css/all.min.css";

// @import 'quill/dist/quill.snow.css';

/* fullpage */
// @import "newpla-modules/newpla-libs/src/fullpage/styles/fullpage.scss";

/* newpla-lib/highlight */
// @import "node_modules/highlight.js/scss/monokai-sublime.scss";
// @import "newpla-modules/newpla-libs/src/highlight/styles/np-hljs.scss";

.npx-page-lg {
    max-width: 992px;
}

.npx-page-md {
    max-width: 768px;
}

.npx-page-sm {
    max-width: 576px;
}

// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1400px

.np-flex-h100 {
    display: flex !important;
    flex-direction: column !important;
    min-height: 1%;
    height: 100% !important;
}

.btn-group-xs > .btn,
.btn-xs {
    padding: 0.25rem 0.4rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 0.2rem;
}

.tab-content {
    height: 100%;
}

.tab-content > .active {
    height: 100% !important;
}

@import "../../../projects/newpla-npx-ng17/styles/newpla-style1.scss";

@import "./styles/car-trans-custom.scss";
@import "./styles/map-markers.scss";
@import "./styles/np-kakao-map.scss";
