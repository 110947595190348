/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo core

// primary control class: applies to all Wijmo controls
.wj-control {
    display: block;
    box-sizing: border-box;
    color: $wj-txt;
    @include backgradient($wj-bkg, $wj-bkg-grd);
    @include prefix(user-select, none, webkit moz ms);

    * {
        box-sizing: inherit;
    }

    // to make contenteditable work on iOS
    // http://stackoverflow.com/questions/21295698/html5-contenteditable-attribute-not-working-properly-on-ios7-mobile-safari
    [contenteditable] {
        @include prefix(user-select, text, webkit moz ms); // Required on iOS
    }

    // remove focus outline
    &:focus,
    *:not(.wj-tabpane *):focus,//WJM-25309, set outline to all elements that are not descendants of wj-tabpane on focus
    .wj-tabpane .wj-control *:focus, //WJM-25430, but still keep removing outline from all wj-control under wj-tabpane
    a:focus {
        outline: none;
    }

    // remove browser-specific elements from input controls
    input {

        &::-ms-clear {
            // remove IE clear button
            display: none;
        }

        &:-ms-input-placeholder {
            // set IE placeholder opacity (like Firefox, need single colon): TFS 457902
            opacity: 0.7; // C1WEB-26755
        }        

        &[type=number] {

            // remove spinner buttons in Chrome
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &:invalid {
            // remove red outline validation in Firefox
            box-shadow: none;
            outline: none; // TFS 327581
        }
    }

    // button elements
    .wj-btn {
        @include backgradient($wj-btn-bkg, $wj-btn-grd, $wj-btn-bkg, rgba(0, 0, 0, .1));
        cursor: pointer;
        color: $wj-btn-txt;
        display: inline-block;
        border-style: none;
        padding: 0 10px;
        text-align: center;
        min-width: 30px;
        white-space: nowrap;

        &:focus,
        &:hover {
            @include backgradient($wj-btn-hvr, $wj-btn-grd, $wj-btn-bkg, rgba(0, 0, 0, .2));
            transition-duration: $wj-tdn-focus;
        }

        &.wj-state-active {
            background: safechangecolor($wj-btn-bkg, 10%);
            //@include backgradient($wj-btn-hvr, $wj-btn-grd, $wj-btn-bkg, rgba(0, 0, 0, .8));
        }

        &[disabled] {
            cursor: default;
            pointer-events: none;
            opacity: $wj-disabled-opacity;
        }
    }

    // button-styled links
    a.wj-btn,
    button.wj-btn:not(.wj-btn-default) {
        // not for buttons in input controls
        border-radius: $wj-bdr-rad;
        padding: 6px 15px;
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
    }
}

// moved to top level for backward compatibility
.wj-header {
    @include backgradient($wj-hdr-bkg, $wj-hdr-grd);
    color: $wj-hdr-txt;
    font-weight: bold;
}

.wj-state-multi-selected {
    background: $wj-msel-bkg;
    color: $wj-msel-txt;
}

.wj-state-selected,
.wj-state-last-selected {
    background: $wj-sel-bkg;
    color: $wj-sel-txt;
}

.wj-state-disabled:not(.wj-popup) { // WJM-19483
    cursor: default;
    pointer-events: none; // TFS 466794
    opacity: $wj-disabled-opacity; // TFS 472233
}

// inline control class: includes border
.wj-content {
    display: inline-block;
    border: $wj-bdr;
    border-radius: $wj-bdr-rad;
    overflow: hidden;
}

.wj-tooltip {
    box-sizing: border-box;
    position: absolute;
    pointer-events: none;
    max-width: 400px;
    padding: $wj-item-pdg;
    background: $wj-tooltip;
    color: $wj-tooltip-txt;
    border: $wj-bdr;
    border-radius: $wj-bdr-rad;
    box-shadow: $wj-box-shadow;
    word-break: break-word; // TFS 426377
    -ms-word-break: break-all;

    &.wj-error-tip {
        background: $wj-error-tooltip;
        color: $wj-error-tooltip-txt;
        white-space: pre-line;
        font-weight: bold;
    }
}

// used in drop-downs and other controls
.wj-dropdown-panel {
    pointer-events: auto; // TFS 390659
    box-shadow: $wj-box-shadow;
}