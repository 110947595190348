/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// flex-map
.wj-flexmap {
    .wj-btn {
        opacity: 0.9;
        padding: 10px 10px 6px 10px !important; 
        border: solid 0.5px gray;
    }
}