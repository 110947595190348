/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo.gauge
.wj-gauge {
    background: transparent;

    &:not(.wj-state-readonly) {
        touch-action: none;
    }

    .wj-face path {
        fill: #f5f5f5;
        stroke: #cbcbcb;
        stroke-width: 1px;
        stroke-linejoin: round;
    }

    .wj-pointer,
    .wj-needle {
        fill: $wj-sel-bkg;
    }

    .wj-ticks {
        stroke-width: 2px;
        stroke: $wj-bkg;
    }

    .wj-min,
    .wj-max,
    .wj-tick-text text {
        font-size: 60%;
        opacity: .5;
    }

    text {
        fill: currentColor;
    }

    svg { // the :not(:root) is to override normalize.css (TFS 422761)
        overflow: visible;
    }

    g {
        cursor: inherit;
    }
}

.wj-radialgauge .wj-value {
    font-size: 150%;
}

.wj-lineargauge {
    height: 1.2em;

    .wj-value {
        fill: $wj-sel-txt;
    }
}
