.np-flex-grid-menu {
    position: absolute;
    z-index: 99999;
    margin-left: 12px;
    margin-top: 26px;
}

.np-flex-grid-menu-icon {
    display: inline-block;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-menu-button' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M0 1.5A1.5 1.5 0 0 1 1.5 0h8A1.5 1.5 0 0 1 11 1.5v2A1.5 1.5 0 0 1 9.5 5h-8A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-8zM14 7H2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zM2 6a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2H2z'/%3E%3Cpath fill-rule='evenodd' d='M15 11H1v-1h14v1zM2 12.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z'/%3E%3Cpath d='M7.823 2.823l-.396-.396A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
}

// WIJMO 전체 컴포넌트 스타일
.wj-content {
    background-color: #ffffff;
    color: #333333;
}

// .wj-state-selected {
//     background: #f99d1c;
//     color: #000;
// }
// .wj-state-multi-selected {
//     background: #f99d1c7d;
//     color: #fff;
// }

.wj-tooltip {
    background-color: #ffffe5;
    color: #333333;
}

// WIJMO FLEX GRID STYLE
.wj-flexgrid {
    border-radius: 0;
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    border-top: 2px solid #808080;

    .wj-cell.wj-header {
        background-color: #e6e6e6;
        color: #444444;
        font-weight: bold;
        border-right: solid 1px #cccccc;
        border-bottom: solid 1px #cccccc;
    }

    .wj-cell:not(.wj-header):not(.wj-group):not(.wj-alt):not(.wj-state-selected):not(.wj-state-multi-selected) {
        background-color: #ffffff;
        color: #333333;
    }

    .wj-cell.wj-alt:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
        background-color: #f9f9f9;
        color: #333333;
    }

    // .wj-cell.wj-state-selected {
    //     background: #f99d1c;
    //     color: #000;
    // }

    // .wj-cell.wj-state-multi-selected {
    //     background: #f99d1c7d;
    //     color: #000;
    // }

    .wj-cell.wj-state-invalid {
        border: 1px solid red;
    }

    .wj-cell.wj-frozen-col {
        border-right: 2px solid #bdbdbd;
    }

    /* frozen area edges */
    .wj-frozen-row {
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    }

    .wj-frozen-col {
        border-right: 1px solid rgba(0, 0, 0, 0.5);
    }

    /* style for the freeze-bar */
    .freeze-bar {
        cursor: col-resize;
        background-color: #8089bf;
        opacity: 0.5;
        z-index: 5;
        position: absolute;
        width: 4px;
        top: 0px;
        bottom: 17px;
    }

    // 버튼 Cell + 버튼 크기
    // 데이터가 많은 경우 버튼으로 인해 row의 높이가 조절되면
    // reload와 같은 경우 계속 row 높이를 다시 그리면서 엄청난 렉이 걸린다.
    // row 높이를 설정하지 않은 경우에도 기본 높이시에 유지되도록 버튼 Cell과 버튼 크기를 조절한다.
    .btn-cell {
        padding: 0.1rem !important;

        // wijmo grid button css
        .btn-wijmo-sm,
        .btn-sm {
            padding: 0.1rem 0.5rem;
            font-size: 0.75rem;
        }
    }

    // 데이터 수정 가능한 컬럼 헤더 표시
    .np-editable-column::before {
        content: '';
        position: absolute;
        top: 0px;
        // bottom: 0px;
        left: 0px;
        // display: inline-block;
        // margin-right: 5px;
        // margin-top: -15px;
        /* 텍스트와의 간격 조정 */
        height: 5px;
        width: 5px;
        background-color: rgb(248, 189, 51);
        // border-radius: 50%;
        /* 원형으로 만들기 */
    }

    // 데이터 수정이 된 Row의 셀들
    .np-edited-row-cell {
        background-color: #ffffe2 !important;

        &.wj-cell.wj-state-selected {
            background: #0085c7 !important;
            color: white !important;
        }

        &.wj-cell.wj-state-multi-selected {
            background: #80adbf !important;
            color: white !important;
        }
    }




}

.np-guage-red.wj-gauge .wj-pointer {
    fill: red;
}

.wj-radialgauge .wj-value {
    font-size: 120%;
}