.map-marker-cus-text-1 {

    .content-text {
        min-width: 24px;
        min-height: 24px;
        padding: 4px 6px 4px 6px;
        font-size: 12px;
        border-radius: 4px;
        text-align: center;
        line-height: 12px;
        cursor: pointer;
        background: #ffffff;
        border: 2px solid #378bfd;
        color: #313131;
    }

    &:after {
        content: "";
        position: absolute;
        margin-left: -12px;
        left: 50%;
        bottom: -11px;
        width: 24px;
        height: 12px;
        // background: url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png) no-repeat 0 bottom;
        // background: url("/assets/images/map/pin4.png") no-repeat 0 bottom;
        background: url("/assets/kakaomap/arrow_drop_down_12px.svg") no-repeat 0 bottom;
        // fill: red;
        background-position: center;
    }

    &.border-gray {
        .content-text {
            border-color: #888;
        }
    }
}

:root {
    --dlvr-status-assigned-color: #FD9D04;
    --dlvr-status-in_processing-color: #0037A7;
    --dlvr-status-completed-color: #2A6B41;
    --dlvr-status-completed-color: #2A6B41;
}

// 주문현황 마커
.map-marker-cco {

    --map-marker-cco-color: #0037A7;

    display: flex;
    align-items: center;
    width: auto;
    height: auto;

    .cco-circle-border {
        padding: 3px;
        border-radius: 50%;
        background-color: #FFF;
        border: 2px solid var(--map-marker-cco-color);
        color: white;
        text-align: center;
        align-items: center;
        cursor: pointer;

        .cco-circle-text {
            min-width: 24px;
            min-height: 24px;

            border-radius: 50%;
            border: 0px solid #FFF;
            background: var(--map-marker-cco-color);

            // display: flex;
            // justify-content: center;
            // align-items: center;

            color: white;
            line-height: 24px;
            text-align: center;
            // font-size: 12px;
            // padding: 6px;

            cursor: pointer;
        }
    }

    .cco-label {
        display: none;
        width: auto;
        min-height: 24px;
        // padding: 4px;
        padding-left: 12px;
        padding-right: 6px;
        border-radius: 0 4px 4px 0;
        font-size: 12px;
        text-align: center;
        line-height: 24px;
        background: #ffffff;
        color: #000000;
        margin-left: -10px;
        border: 2px solid var(--map-marker-cco-color);
        z-index: -1 !important;
    }

    &:hover {
        z-index: 100 !important;

        .cco-circle-border {
            z-index: 100 !important;
        }

        .cco-label {
            display: block;
            z-index: 90 !important;
        }
    }

    &.cco-red {
        --map-marker-cco-color: #E64001;
    }

    &.cco-orange {
        --map-marker-cco-color: #FD9D04;
    }

    &.cco-blue {
        --map-marker-cco-color: #0037A7;
    }

    &.cco-green {
        --map-marker-cco-color: #2A6B41;
    }

    &.cco-gray {
        --map-marker-cco-color: #888;
    }

    &.cco-outline-red {
        .cco-circle-border {
            border-color: #E64001;
        }

        .cco-label {
            border-color: #E64001;
        }

    }

}