/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo.grid.transposed.multirow
.wj-transposed-multirow {

    .wj-cell {
        &.wj-record-end:not(.wj-header) {
            border-right-color: #787878;
        }

        &.wj-group-end {
            border-bottom-color: #787878;
        }
    }
}