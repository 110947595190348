/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo.barcode.*

.wj-barcode {
    // width: 100%;
    height: 60px;
    display: inline-block;

    &.wj-state-invalid {
        border: 1.2px solid $wj-invalid;
    }
}

.wj-barcode-qrcode {
    width: 100px;
    height: 100px;
}

// no width - variable width barcode
// .wj-barcode-codabar {
//     width: 155px;
// }

.wj-barcode-ean8 {
    width: 85px;
}

.wj-barcode-ean13 {
    width: 140px;
}

// no width - variable width barcode
// .wj-barcode-code39 {
//     width: 150px;
// }

// no width - variable width barcode
// .wj-barcode-code128 {
//     width: 180px;
// }

// no width - variable width barcode
// .wj-barcode-gs1_128 {
//     width: 180px;
// }

.wj-barcode-upca {
    width: 115px;
}

.wj-barcode-upce0 {
    width: 70px;
}

.wj-barcode-upce1 {
    width: 70px;
}

.wj-barcode-gs1databar-omnidirectional {
    width: 100px;
}

.wj-barcode-gs1databar-truncated {
    width: 100px;
}

.wj-barcode-gs1databar-stacked {
    width: 60px;
}

.wj-barcode-gs1databar-stacked-omnidirectional {
    width: 60px;
}

.wj-barcode-gs1databar-limited {
    width: 85px;
}

// no width - variable width barcode
// .wj-barcode-gs1databar-expanded {
//     width: 240px;
// }

// no width - variable width barcode
// .wj-barcode-gs1databar-expanded-stacked {
//     width: 105px;
// }

// no width - variable width barcode
// .wj-barcode-pdf417 {
//     width: 110px;
// }

.wj-barcode-micropdf417 {
    width: 105px;
}

.wj-barcode-datamatrix {
    width: 85px;
    height: 85px;
}

.wj-barcode-code49 {
    width: 85px;
}

// no width - variable width barcode
// .wj-barcode-code93 {
//     width: 90px;
// }

.wj-barcode-itf14 {
    width: 170px;
}

// no width - variable width barcode
// .wj-barcode-interleaved2of5 {
//     width: 130px;
// }

.wj-barcode-japanese-postal {
    width: 140px;
    height: 40px;
}

