.np-col-set {
    // d-flex  
    display: flex !important;
    // align-self-stretch
    align-self: stretch !important;

    border-bottom: 1px solid #ddd;
    background-color: white;

    .np-col-head {
        // d-flex  
        display: flex !important;
        // align-items-center {
        align-items: center !important;
        // bg-color
        background-color: #f7f7f7;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
        min-width: 100px;
        justify-content: center;
        white-space: nowrap;
    }

    .np-col-cell {
        // d-flex  
        display: flex !important;
        // align-items-center {
        align-items: center !important;
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
        width: 100%;
        white-space: nowrap;
    }
}

.np-map-cuso {

    &.top-left {
        background: url('/assets/np-kakao-map/svg/diag-arrow-ctobe.svg') no-repeat center center;
        background-size: contain;
    }

    &.top-right {
        background: url('/assets/np-kakao-map/svg/diag-arrow-ctobs.svg') no-repeat center center;
        background-size: contain;
    }

    &.bottom-left {
        background: url('/assets/np-kakao-map/svg/diag-arrow-ctote.svg') no-repeat center center;
        background-size: contain;
    }

    &.bottom-right {
        background: url('/assets/np-kakao-map/svg/diag-arrow-ctots.svg') no-repeat center center;
        background-size: contain;
    }

    .label-text-box {
        display: none;
        justify-content: center;
        /* 가로 정렬 */
        align-items: bottom;
        /* 세로 정렬 (원하는 경우) */

        &.show {
            display: flex;
        }

        .label-text {
            font-size: 12px;
            padding: 2px 4px 2px 4px;
            background-color: white;
            border: 1px solid #aaa;
            position: absolute;
        }
    }

    &:hover {
        .label-text-box {
            display: flex;
        }
    }

    .circle-marker {

        --cuso-circle-line-color: #0037A7;
        --cuso-circle-bg-color: #0037A7;
        --cuso-circle-text-color: #FFF;

        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;

        border-radius: 50%;
        background-color: #FFF;
        border: 1px solid var(--cuso-circle-line-color);

        .circle-text {
            // padding: 6px;
            color: var(--cuso-circle-text-color);
            background: var(--cuso-circle-bg-color);
            cursor: pointer;
            border-radius: 50%;
            width: 22px;
            height: 22px;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.circle-blue {
            --cuso-circle-line-color: #0037A7;
            --cuso-circle-bg-color: #0037A7;
        }

        &.circle-red {
            --cuso-circle-line-color: #E64001;
            --cuso-circle-bg-color: #E64001;
        }

        &.circle-pink {
            --cuso-circle-line-color: #e928ff;
            --cuso-circle-bg-color: #e928ff;
        }

        &.circle-orange {
            --cuso-circle-line-color: orange;
            --cuso-circle-bg-color: orange;
        }

        &.circle-brown {
            --cuso-circle-line-color: #74500d;
            --cuso-circle-bg-color: #74500d;
        }

        &.circle-green {
            --cuso-circle-line-color: #2A6B41;
            --cuso-circle-bg-color: #2A6B41;
        }

        &.circle-gray {
            --cuso-circle-line-color: #888;
            --cuso-circle-bg-color: #888;
        }

        &.circle-outline-red {
            --cuso-circle-line-color: red;
            --cuso-circle-bg-color: #fff;
            --cuso-circle-text-color: red;
            border-width: 2px;

            .circle-text {
                font-weight: bold;
            }
        }

        &.circle-outline-green {
            --cuso-circle-line-color: #2A6B41;
            --cuso-circle-bg-color: #fff;
            --cuso-circle-text-color: #2A6B41ed;
            border-width: 2px;

            .circle-text {
                font-weight: bold;
            }
        }

        &.circle-fluorescent {
            --cuso-circle-line-color: #000000;
            --cuso-circle-bg-color: #31fe79;
            --cuso-circle-text-color: #000;
            border-width: 3px;

            .circle-text {
                font-weight: bold;
            }
        }

        &.circle-sm {
            width: 20px;
            height: 20px;

            .circle-text {
                cursor: pointer;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                font-size: 10px;
            }

        }

        // .cco-label {
        //     display: none;
        //     width: auto;
        //     min-height: 24px;
        //     // padding: 4px;
        //     padding-left: 12px;
        //     padding-right: 6px;
        //     border-radius: 0 4px 4px 0;
        //     font-size: 12px;
        //     text-align: center;
        //     line-height: 24px;
        //     background: #ffffff;
        //     color: #000000;
        //     margin-left: -10px;
        //     border: 2px solid var(--map-marker-cco-color);
        //     z-index: -1 !important;
        // }

    }

    .circle-info-window {
        display: none;
        // justify-content: center;
        // align-items: bottom;
        position: absolute;
        z-index: -1;

        left: 10px;
        top: 10px;
        right: inherit;
        bottom: inherit;

        .circle-info-content {
            display: block;
            border: 1px solid black;
            padding: 15px 15px 15px 15px;
            bottom: 0;
            background-color: white;
            color: #000;
            max-height: 200px;
            overflow: auto;
        }
    }

    &.top-left {
        .circle-info-window {
            left: inherit;
            top: inherit;
            right: 10px;
            bottom: 10px;
        }
    }

    &.top-right {
        .circle-info-window {
            left: 11px;
            top: inherit;
            right: inherit;
            bottom: 11px;

            // left: 10px;
            // top: inherit;
            // right: inherit;
            // bottom: inherit;
            // transform: translate(0, -50%);
        }
    }

    &.bottom-left {
        .circle-info-window {
            left: inherit;
            top: 10px;
            right: 10px;
            bottom: inherit;
        }
    }

    &.bottom-right {
        .circle-info-window {
            left: 10px;
            top: 10px;
            right: inherit;
            bottom: inherit;
        }
    }

    &:hover {
        .circle-info-window {
            display: flex;
        }
    }


}

.sls-offcanvas-lg {
    --bs-offcanvas-width: 800px;
}