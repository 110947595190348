/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

//
// constants
//

$white: #fff !default;
$grey-100: #f8f9fa !default;
$grey-200: #e9ecef !default;
$grey-300: #dee2e6 !default;
$grey-400: #ced4da !default;
$grey-500: #adb5bd !default;
$grey-600: #6c757d !default;
$grey-700: #495057 !default;
$grey-800: #343a40 !default;
$grey-900: #212529 !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// material design colors
$mdl-cyan: rgb(0, 188, 212) !default;
$mdl-cyan-inner: rgb(0, 151, 167) !default;
$mdl-teal: rgb(0, 150, 136) !default;
$mdl-teal-inner: rgb(0, 121, 107) !default;
$mdl-green: rgb(76, 175, 80) !default;
$mdl-green-inner: rgb(56, 142, 60) !default;
$mdl-light-green: rgb(139, 195, 74) !default;
$mdl-light-green-inner: rgb(104, 159, 56) !default;
$mdl-lime: rgb(205, 220, 57) !default;
$mdl-lime-inner: rgb(175, 180, 43) !default;
$mdl-yellow: rgb(255, 235, 59) !default;
$mdl-yellow-inner: rgb(251, 192, 45) !default;
$mdl-amber: rgb(255, 193, 7) !default;
$mdl-amber-inner: rgb(255, 160, 0) !default;
$mdl-orange: rgb(255, 152, 0) !default;
$mdl-orange-inner: rgb(245, 124, 0) !default;
$mdl-brown: rgb(121, 85, 72) !default;
$mdl-brown-inner: rgb(93, 64, 55) !default;
$mdl-blue-grey: rgb(96, 125, 139) !default;
$mdl-blue-grey-inner: rgb(69, 90, 100) !default;
$mdl-grey: rgb(158, 158, 158) !default;
$mdl-grey-inner: rgb(97, 97, 97) !default;
$mdl-deep-orange: rgb(255, 87, 34) !default;
$mdl-deep-orange-inner: rgb(230, 74, 25) !default;
$mdl-red: rgb(244, 67, 54) !default;
$mdl-red-inner: rgb(211, 47, 47) !default;
$mdl-pink: rgb(233, 30, 99) !default;
$mdl-pink-inner: rgb(194, 24, 91) !default;
$mdl-purple: rgb(156, 39, 176) !default;
$mdl-purple-inner: rgb(123, 31, 162) !default;
$mdl-deep-purple: rgb(103, 58, 183) !default;
$mdl-deep-purple-inner: rgb(81, 45, 168) !default;
$mdl-indigo: rgb(63, 81, 181) !default;
$mdl-indigo-inner: rgb(48, 63, 159) !default;
$mdl-blue: rgb(33, 150, 243) !default;
$mdl-blue-inner: rgb(25, 118, 210) !default;
$mdl-light-blue: rgb(3, 169, 244) !default;
$mdl-light-blue-inner: rgb(2, 136, 209) !default;
