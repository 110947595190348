.np-flex-box {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;

    .np-flex-box-body {
        flex: 1 1 auto;
        padding: 1rem;
    }
}


.form-control {
    &:read-only {
        background-color: var(--bs-secondary-bg);
        opacity: 1;
    }
}

// select 박스 와 np-fcv-invalid
.wijmo-bs5-form .np-fcv-invalid .form-select {
    border-color: #dc3545;
}

.wijmo-bs5-form .np-fcv-invalid .form-select:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}


// wijmo grid cell
.wj-cell {
    // 마우스로 cell select 효과를 무시하고 강조함.

    // order damage 셀 강조
    &.wj-cell-damage-important {
        color: white !important;
        background-color: $danger !important;
    }

}

.wj-cell:not(.wj-state-selected):not(.wj-state-multi-selected) {
    // 마우스로 cell select 효과가 우선시됨.

    // order damage 셀 강조
    &.wj-cell-damage {
        color: white !important;
        background-color: $danger !important;
    }

    &.wj-cell-total {
        color: #444 !important;
        background-color: #e1e1e1 !important;
    }

    &.wj-cell-work-type {
        &.OFF_TYPE1 {
            color: $danger !important;
            font-weight: bold;
        }

        &.OFF_TYPE2 {
            color: $primary !important;
            font-weight: bold;
        }
    }
}

// wijmo input
.wj-content {
    &.form-control {
        background-color: var(--bs-body-bg) !important;
        border: var(--bs-border-width) solid var(--bs-border-color) !important;
        border-radius: var(--bs-border-radius) !important;
        padding: 0;

        .wj-form-control {
            padding: 0.375rem 0.75rem !important;
        }
    }
}

//
.wj-flexgrid {
    &.flexgrid-h5 {
        .wj-cell {
            // font-size: 0.9375rem !important;
            font-size: $h5-font-size !important;
        }
    }
}

.wj-chart-label-white {
    &.wj-flexchart .wj-data-labels .wj-data-label {
        fill: white !important;
    }
}

.job-delivery-bg {
    background-color: #0037A7;
}

.job-pickup-bg {
    background-color: #e928ff;
}

.job-shipment-bg {
    background-color: #74500d;
}

.min-height-1 {
    min-height: 1% !important
}

.p-sls-xs {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
}

.tooltip-no-max-width {
    .tooltip-inner {
        max-width: none;
    }
}

ul.pagination {
    padding: 0 !important;
    margin: 0 !important;
}