@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$body-bg: #fff;
$body-color: #111;

$font-family-sans-serif: 'Noto Sans KR', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// 14px;
// $font-size-base: 0.875rem !default;
// 12px;
$font-size-base: 0.75rem !default; 

$enable-cssgrid: true;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
    3xl: 1600px,
    4xl: 1840px,
    ) !default;

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    3xl: 1560px,
    4xl: 1800px,
    ) !default;

$enable-dark-mode: false !default;

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
// @import "bootstrap-icons/font/bootstrap-icons.scss"
// @import "bootstrap-icons/font/bootstrap-icons.scss";

html,
body {
    height: 100%;
    // min-width: 393px;
    min-width: 0;
}

// .modal-content {
//     border: none;
//     border-radius: 0;
// }

.modal-header {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    // background-color: rgb(43, 74, 114);
    // color: #fff;
    // border-radius: 0;
}

.modal-footer {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 0;
    // background-color: rgb(240, 240, 240);
}

// .modal-backdrop {
//     background-color: #3a4d5e;
// }

.np-modal-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

// @media (min-width: 576px) {
//     .np-bs5-modal-free {
//         max-width: inherit;
//         margin-left: auto;
//         margin-right: auto;

//         padding: 0.5rem;

//         .modal-content {
//             width: fit-content;
//             margin-left: auto;
//             margin-right: auto;
//         }

//         .np-modal-content {
//             width: 100%;
//         }
//     }
// }

// .btn-group-xs > .btn,
// .btn-xs {
//     padding: 0.25rem 0.3rem;
//     font-size: 0.765625rem;
//     line-height: 1.3;
//     border-radius: 0.2rem;
// }


// .popover-wide {
//     max-width: 100%;

//     .popover-header {
//         background-color: black;
//         color: #fff;
//     }
// }