/*!
    *
    * Wijmo Library 5.20232.939
    * https://developer.mescius.com/wijmo
    *
    * Copyright(c) MESCIUS inc. All rights reserved.
    *
    * Licensed under the End-User License Agreement For MESCIUS Wijmo Software.
    * us.sales@mescius.com
    * https://developer.mescius.com/wijmo/licensing
    *
    */

// wijmo.grid.filter
.wj-flexgrid {
    .wj-cell.wj-filter-on .wj-elem-filter { // show filter on
        opacity: .9;
    }

    .wj-cell.wj-filter-off .wj-elem-filter { // hide filter off
        opacity: .5;
    }
}

.wj-columnfiltereditor {
    padding: 10px;
    min-width: 240px; // TFS 435129
    max-width: 400px;

    .wj-filtertype {
        text-align: right;
        margin: 14px 0px;
        font-size: 12px;
    }

    .wj-control {
        margin-bottom: 6px;
        width: 100%;
    }

    .wj-valuefilter-editor,
    .wj-conditionfilter-editor {
        margin-top: 1em;
    }

    .wj-conditionfilter-editor > div > label { //WJM-25857  
        display: block;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .wj-listbox {
        height: 150px;

        &:not(.wj-state-focused) {
            .wj-listbox-item.wj-state-selected {
                color: inherit;
                background: transparent;

                &:hover {
                    background: $wj-item-hvr;
                    transition-duration: $wj-tdn-focus;
                }
            }
        }

    }

    label { // TFS 399740
        display: inline-block;
        width: auto;
    }

    a {
        color: inherit;
        font-size: inherit;
        font-weight: bold;

        &.wj-state-disabled {
            text-decoration: none;
        }
    }

    .wj-sort-buttons .wj-btn {
        min-width: 95px;
    }
}
