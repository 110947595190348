@import "variable";

@if $enable-np-fcv-valid {

    .np-valid-feedback {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: $np-fcv-valid-color;
    }

    .np-valid-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: 0.1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #fff;
        background-color: rgba(40, 167, 69, 0.9);
        border-radius: 0.25rem;
    }

    .np-fg-valid {
        border: 1px solid $np-fcv-valid-color;
    }

    .np-fcv-valid {

        .np-fcv-label {
            color: $np-fcv-valid-color;
        }

        // BOOTSTRAP
        .form-control {
            border-color: $np-fcv-valid-color;
            // padding-right: calc(1.5em + 0.75rem);
            padding-right: calc(1.5em + 0.5rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

            &:focus {
                border-color: #28a745;
                box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
            }
        }

        select.form-control {
            background-position: right calc(0.375em + 0.1875rem + 0.75em) center;
        }

        .np-valid-feedback {
            display: block;
        }

        &:hover {
            .np-valid-tooltip {
                display: block;
            }
        }
    }
}

@if $enable-np-fcv-invalid {

    .np-invalid-feedback {
        display: none;
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: $np-fcv-invalid-color;
    }

    .np-invalid-tooltip {
        position: absolute;
        // top: 100%;
        top: auto;
        z-index: 5;
        display: none;
        max-width: 200px;
        // max-width: 100%;
        padding: 0.25rem 0.5rem;
        margin-top: 0.1rem;
        font-size: 0.875rem;
        line-height: 1.5;
        color: #fff;
        background-color: rgba(220, 53, 69, 0.9);
        border-radius: 0.25rem;
    }

    .np-fg-invalid {
        border: 1px solid $np-fcv-invalid-color;
    }

    .np-fcv-invalid {
        
        .np-fcv-label {
            color: $np-fcv-invalid-color;
        }

        // BOOTSTRAP
        .form-control {
            border-color: $np-fcv-invalid-color;
            // padding-right: calc(1.5em + 0.75rem);
            padding-right: calc(1.5em + 0.5rem);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

            &:focus {
                border-color: #dc3545;
                box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
            }
        }

        select.form-control {
            background-position: right calc(0.375em + 0.1875rem + 0.75em) center;
        }

        .np-invalid-feedback {
            display: block;
        }

        &:hover {
            .np-invalid-tooltip {
                display: block;
            }
        }
    }
}

.np-fcv-error {
    color: #dc3545;
}